<template>
  <section class="medium-courses" v-if="mediumCourses.length">
    <b-row>
      <b-col md order-md="0" order="1" class="mt-3 mt-md-0">
        <MediumCourseTeaser v-for="(mediumCourse, index) in mediumCourses"
                                  :key="`self-${index}`"
                                  class="mb-3"
                                  :mediumCourse="mediumCourse"/>                   
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {StudentPageService} from "../../../../services/api.service"
import MediumCourseTeaser from "../../../parts/courses/MediumCourseTeaser.vue"

export default {
  name: "StudentMediumCourses",
  components: {
    MediumCourseTeaser
  },
  data() {
    return {
      selfstudy: [],
      mediumCourses: [],
    }
  },
  methods: {
    async loadData() {
      const res = await StudentPageService.homeSelfStudy();
      this.selfstudy = res.data.data.selfstudy.data;
      this.mediumCourses = res.data.data.mediumCourses;
    },
    selfStudy(material) {
      this.$router.push({
        name: 'SelfStudyLesson',
        params: { id: material.id }
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped lang="scss">
.standart_card {
  cursor: pointer;
  &:hover {
    background: #F8F9FB;
  }
}
.spacecard {
  padding: 16px;
  &__card {
    padding: 24px;
    border-radius: 16px;
  }
}
@media(max-width: 768px) {
  .spacecard {
    padding: 12px;
    &__card {
      padding: 12px;
    }
    h3 {
      font-size: 14px;
    }
  }
}
</style>
