<template>
    <div class="light-course-teaser" :class="{disabled: !mediumCourse.course.enabled }">
      <b-row class="align-items-center">
        <b-col md cols="8">
          <div class="content">
            <div>
              <div>
                <span class="course-badge"><JoystickSvg/>{{ $t('mediumcourse') }}</span>
                <template v-if="mediumCourse.course.enabled">
                  <span v-if="progress < 100" class="progress-badge">{{ progress }}%</span>
                  <span v-else class="course-badge finished"><TickSvg/>{{ $t('finished') }}</span>
                </template>
              </div>
            </div>
            <h4>{{ mediumCourse.course.title }}</h4>
            <div class="mt-5">
              <b-button v-if="mediumCourse.course.enabled"
                        variant="text"
                        class="pl-0 text-primary font-weight-bold"
                        :to="{ name: 'StudentMediumCourseModules', params: { courseId: mediumCourse.course.id } }">
                <span v-if="progress == 0">{{ $t('start') }}</span>
                <span v-else-if="progress == 100" class="text-muted">{{ $t('open') }}</span>
                <span v-else>{{ $t('continue') }}</span>
              </b-button>
              <span v-else class="closed-span mt-2" ><LockSvg class="mr-2"/>{{ $t('course_closed') }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="auto" cols="4">
          <ProgressPie v-if="mediumCourse.course.enabled" size="medium" :progress="progress" class="progress-wrapper"/>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import JoystickSvg from '@/assets/svg/joystick.svg'
  import TickSvg from '@/assets/svg/tick.svg'
  import ProgressPie from "../../parts/general/ProgressPie.vue"
  import LockSvg from "@/assets/svg/lock.svg"
  
  export default {
    name: "MediumCourseTeaser",
    components: {
      JoystickSvg,
      TickSvg,
      ProgressPie,
      LockSvg
    },
    props: {
      mediumCourse: {
        type: Object,
        required: true
      }
    },
    computed: {
      progress() {
        return this.mediumCourse.selfStudyLessons.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.progress
        },0) / this.mediumCourse.selfStudyLessons.length;
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .light-course-teaser {
    background: white;
    position: relative;
    border-radius: 16px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .content {
      padding: 12px 22px;
      .course-badge, .progress-badge {
        display: inline-block;
        color: white;
        margin-right: 4px;
        padding: 6px 10px;
        border-radius: 7px;
        font-weight: 700;
        font-size: 11px;
        svg {
          width: 16px;
          fill: white;
          margin-right: 4px;
        }
      }
      .course-badge {
        background: #C887FF;
        &.finished {
          svg {
            margin-right: 0;
          }
          background: #27AE60;
        }
      }
      .progress-badge {
        background: #ECC03B;
      }
      h4 {
        font-weight: 700;
        font-size: 18px;
        margin-top: 12px;
      }
    }
    .img-wrapper {
      padding-right: 24px;
      img {
        width: 140px;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
      }
    }
  }
  
  .progress-wrapper {
    font-size: 7em !important;
    padding-right: 10px;
  }
  </style>
  